<template>
  <div class="TableSelectStatus">
    <v-select v-model="statusId" :as="['name', 'id', 'id']" :from="selectOptions" @select="updateData">
      <template #option="{option}"><span :style="`color: ${option.raw.colour}`">{{ option.label }}</span></template>
      <template #selected="{option}"><span :style="`color: ${option.raw.colour}`">{{ option.label }}</span></template>
    </v-select>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import { vSelect } from '@desislavsd/vue-select'
import '@desislavsd/vue-select/dist/vue-select.css'

export default {
  name: 'TableSelectStatus',
  props: {
    data: {
      type: Object,
      required: true,
    },    
  },
  components: {
    vSelect,
  },
  data() {
    return {
      statusId: this.$props.data.status_id,
    }
  },
  computed:{
    ...mapGetters({
      dictionary: 'dictionary',
      status: 'auth/status',
    }),  
    selectOptions() {
      return this.status.filter(item => item.is_active).sort((a, b) => a.id - b.id)
    }
  },
  methods: {
    ...mapActions({
      postOrder: "orders/postOrder",
    }),
    updateData($event) {
      const order = { status_id: $event.value }

      this.postOrder({ order, id: this.$props.data.id})
        .then(( data ) => {
          this.$emit('update', data)
          this.$buefy.toast.open({
              duration: 5000,
              message: data.alert[1],
              type: 'is-success',
              position: 'is-bottom'
            })  
        })

    }
  }
}
</script>

<style scoped>
.TableSelectStatus::v-deep .v-select-btn-clear {
  display: none;
}
.TableSelectStatus::v-deep .v-select-btn-dd {
  display: flex;
  align-items: center;
  box-shadow: none;
}
.TableSelectStatus::v-deep .v-select-inp {
  box-shadow: none;
}
</style>
