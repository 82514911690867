<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service" class="is-active"
            ><a>Zlecenia serwisowe</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle ym-toptitle-wrap">
          <h2 class="title level-left">Zlecenia serwisowe</h2>
          <div class="buttons level-right">
            <a
              v-if="selectedRowKeys.length > 0"
              @click.prevent="triggerSendModal"
              class="button is-medium"
              ><icon name="mail"></icon>Wyślij powiadomienie
              <span>do wielu...</span></a
            >
            <a @click.prevent="addNewOrderFromForm" class="button is-medium"
              ><icon name="plus"></icon><span>Dodaj</span> nowe zlecenie z
              formularza</a
            >
            <a @click.prevent="addNewOrder" class="button is-medium is-dark"
              ><icon name="plus"></icon><span>Dodaj</span> nowe zlecenie</a
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div
      class="columns is-variable is-6 is-multiline"
      style="padding-bottom: 0"
    >
      <div class="column is-12">
        <div class="b-field b-field-flex search-flex">
          <div class="ym-searchbox" style="margin-bottom: 8px; width: 100%">
            <b-input
              ref="searchInput"
              v-model="searchQuery"
              size="is-medium"
              placeholder="SZUKAJ..."
            ></b-input>
            <div
              class="field level level-item level-right ym-searchbox-right"
              v-if="this.searchQuery"
            >
              również usunięte
              <b-checkbox
                v-model="searchDeleted"
                size="is-medium"
                true-value="1"
                false-value="0"
                @change.native="toggleSearchDeleted"
              >
              </b-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="panelbox flex">
          <div class="b-field b-field-flex" style="flex: 1 1 auto">
            <b-datepicker
              :first-day-of-week="1"
              :mobile-native="false"
              @input="searchOrdersFromRange"
              placeholder="Wybierz przedział dat naprawy"
              v-model="dates"
              icon="calendar-today"
              position="is-bottom-left"
              range
            >
            </b-datepicker>
            <div
              v-if="dates.length > 0"
              @click="allOrders"
              class="button is-primary is-medium has-icon"
            >
              <icon name="x"></icon>
            </div>
          </div>
          <div class="b-field b-field-flex" v-if="order_categories.length">
            <b-select
              @input="searchOrdersFromStatus"
              v-model="category_id"
              placeholder="Wybierz kategorie zlecenia"
              expanded
              style="width: 100%"
            >
              <option :value="null">wszystkie</option>
              <option
                v-for="category in order_categories.filter(
                  (item) => item.is_active
                )"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </b-select>
          </div>
          <div class="b-field b-field-flex">
            <b-select
              @input="searchOrdersFromStatus"
              v-model="status_id"
              placeholder="Wybierz status zlecenia"
              expanded
              style="width: 100%"
            >
              <option :value="null">wszystkie</option>
              <option
                v-for="status in this.$store.state.auth.status"
                :key="status.id"
                :value="status.id"
                :style="`color: ${status.colour}`"
              >
                {{ status.name }}
              </option>
            </b-select>
          </div>
          <div class="b-field b-field-flex">
            <b-select
              @input="searchOrdersFromStatus"
              v-model="technical_id"
              placeholder="Wybierz serwisanta"
              expanded
              style="width: 100%"
            >
              <option :value="null">wszystkie</option>
              <option
                v-for="techGuy in this.technicians"
                :key="techGuy.id"
                :value="techGuy.id"
              >
                {{ techGuy.firstname + " " + techGuy.lastname }}
              </option>
            </b-select>
          </div>
          <button @click="showTableModal = true" class="button is-medium">
            <icon name="settings"></icon>
          </button>
          <div
            v-if="isSearch"
            @click="cleanSearch"
            class="button is-black is-medium has-icon"
          >
            <icon name="x"></icon> Usuń filtry
          </div>
        </div>
      </div>
      <div
        v-if="rawTableColumns && rawTableColumns.default"
        class="column is-12"
      >
        <ve-table
          ref="tableRef"
          border-y
          :columns="columns"
          :table-data="orders"
          :event-custom-option="eventCustomOption"
          :cell-style-option="cellStyleOption"
          :cellSelectionOption="cellSelectionOption"
          row-key-field-name="id"
          :checkbox-option="checkboxOption"
          :rowStyleOption="rowStyleOption"
          :columnHiddenOption="columnHiddenOption"
          max-height="calc(100vh - 330px)"
          :fixed-header="true"
        />
        <div
          class="table-pagination"
          v-show="Object.keys(orders).length > 0 && total >= perPage"
        >
          <ve-pagination
            :total="total"
            :page-index="page"
            :page-size="perPage"
            :page-size-option="[5, 10, 15, 25, 50, 100]"
            @on-page-number-change="onPageChange"
            @on-page-size-change="onPageSizeChange"
          />
        </div>
        <custom-modal v-model="showTableModal">
          <template v-slot:title>Dane wyświetlane na liście zleceń</template>
          <div style="margin-top: 16px">
            <div
              v-for="column in columns.filter((item) => item.canHide)"
              :key="column.key"
            >
              <b-checkbox v-model="checkboxGroup" :native-value="column.key">
                {{ column.title }}
              </b-checkbox>
            </div>
          </div>
          <template v-slot:actions>
            <div class="button is-black is-medium" @click="changeTableColumns">
              Ustaw
            </div>
          </template>
        </custom-modal>
        <custom-modal
          v-model="showCorrespondenceModal"
          @closed="emptyModalTrigger"
        >
          <template v-slot:title
            >Korespondencja z {{ modalOrder?.number }}</template
          >
          <div style="margin-top: 16px; margin-bottom: 16px; padding: 0 16px">
            <ValidationObserver
              v-slot="{ handleSubmit, errors }"
              ref="correspondenceObserver"
            >
              <form @submit.prevent="handleSubmit(onCorrespondenceSendSubmit)">
                <div
                  class="field"
                  :class="
                    errors['subject'] && errors['subject'].length > 0
                      ? 'v-field v-error'
                      : ''
                  "
                >
                  <div class="ym-label">
                    <label for="f_company_short">Temat</label>
                  </div>
                  <div class="control is-medium">
                    <ValidationProvider rules="required" name="subject">
                      <input
                        name="subject"
                        v-model="correspondence.subject"
                        class="input is-medium"
                      />
                    </ValidationProvider>
                    <span
                      v-if="errors['subject'] && errors['subject'].length > 0"
                      class="help is-danger"
                      >{{ errors["subject"][0] }}</span
                    >
                  </div>
                </div>
                <div
                  class="field"
                  :class="
                    errors['message'] && errors['message'].length > 0
                      ? 'v-field v-error'
                      : ''
                  "
                >
                  <div class="ym-label">
                    <label for="f_company_short">Wiadomość</label>
                  </div>
                  <div class="control is-medium">
                    <ValidationProvider rules="required" name="message">
                      <textarea
                        name="message"
                        v-model="correspondence.message"
                        class="input is-medium"
                      />
                    </ValidationProvider>
                    <span
                      v-if="errors['message'] && errors['message'].length > 0"
                      class="help is-danger"
                      >{{ errors["message"][0] }}</span
                    >
                  </div>
                </div>
                <button class="button is-black is-medium">Wyślij</button>
              </form>
            </ValidationObserver>
          </div>
        </custom-modal>
        <custom-modal v-model="showSendModal" @closed="emptyModalTrigger">
          <template v-slot:title>Powiadomienie</template>
          <div style="margin-top: 16px; margin-bottom: 16px; padding: 0 16px">
            <ValidationObserver
              v-slot="{ handleSubmit, errors }"
              ref="observer"
            >
              <form @submit.prevent="handleSubmit(onEmailSendSubmit)">
                <div
                  class="field"
                  :class="
                    errors['subject'] && errors['subject'].length > 0
                      ? 'v-field v-error'
                      : ''
                  "
                >
                  <div class="ym-label">
                    <label for="f_company_short">Temat</label>
                  </div>
                  <div class="control is-medium">
                    <ValidationProvider rules="required" name="subject">
                      <input
                        name="subject"
                        v-model="mail.subject"
                        class="input is-medium"
                      />
                    </ValidationProvider>
                    <span
                      v-if="errors['subject'] && errors['subject'].length > 0"
                      class="help is-danger"
                      >{{ errors["subject"][0] }}</span
                    >
                  </div>
                </div>
                <div
                  class="field"
                  :class="
                    errors['content'] && errors['content'].length > 0
                      ? 'v-field v-error'
                      : ''
                  "
                >
                  <div class="ym-label">
                    <label for="f_company_short">Wiadomość</label>
                  </div>
                  <div class="control is-medium">
                    <ValidationProvider rules="required" name="content">
                      <textarea
                        name="content"
                        v-model="mail.content"
                        class="input is-medium"
                      />
                    </ValidationProvider>
                    <span
                      v-if="errors['content'] && errors['content'].length > 0"
                      class="help is-danger"
                      >{{ errors["content"][0] }}</span
                    >
                  </div>
                </div>
                <button class="button is-black is-medium">Wyślij</button>
              </form>
            </ValidationObserver>
          </div>
        </custom-modal>
        <custom-modal v-model="showStatusModal" @closed="emptyModalTrigger">
          <template v-slot:title>Zmiana statusu</template>
          <div style="margin-top: 16px; margin-bottom: 16px; padding: 0 16px">
            <b-field label="Status">
              <b-select v-model="modalStatusId" placeholder="Wybierz">
                <option
                  v-for="item in status
                    .filter((item) => item.is_active)
                    .filter((item) => item.id !== 10)
                    .sort((a, b) => a.id - b.id)"
                  :value="item.id"
                  :key="item.id"
                  :style="`color: ${item.colour}`"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <template v-slot:actions>
            <div class="button is-black is-medium" @click="changeOrderStatus">
              Zmień
            </div>
          </template>
        </custom-modal>
        <custom-modal v-model="showFromOrderModal">
          <template v-slot:title>Stwórz zlecenie za pomocą formularza</template>
          <div style="margin-top: 16px; margin-bottom: 16px; padding: 0 16px">
            <ul>
              <li
                v-for="form in formTemplates"
                :key="form.id"
                v-show="form.is_active"
              >
                <a
                  :href="formUrl.content + '/form/' + form.id"
                  target="_blank"
                  rel="noopener noreferrer"
                  @click="showFromOrderModal = false"
                  >{{ form.name }}</a
                >
              </li>
            </ul>
          </div>
        </custom-modal>
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import debounce from "@/utils/debounce";
import YMmodal from "@/components/Modal.vue";
import CustomModal from "@/components/CustomModal";
import TableSelectStatus from "@/components/TableSelectStatus";
import TableSelectTechnican from "@/components/TableSelectTechnican";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { format } from "date-fns";

export default {
  name: "Service",
  components: {
    FixedHeader,
    CustomModal,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      columnHiddenOption: {
        defaultHiddenColumnKeys: [],
      },
      rawTableColumns: null,
      correspondence: {
        subject: "",
        message: "",
      },
      mail: {
        subject: "",
        content: "",
      },
      modalStatusId: null,
      modalOrder: null,
      showCorrespondenceModal: false,
      showFromOrderModal: false,
      showStatusModal: false,
      showSendModal: false,
      checkboxGroup: [],
      showTableModal: false,
      warrantyStatus: [],
      serviceType: [],
      orders: [],
      technicians: [],
      loading: false,
      total: 0,
      sortField: "number",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      page: this.$store.state.records.page,
      perPage: 100,
      selected: null,
      watchInPause: false,
      eventCustomOption: {
        bodyCellEvents: ({ row, column }) => {
          return {
            click: () => {
              const exceptions = [
                "action_buttons",
                "checkbox",
                "status_id",
                "technical_name",
              ];
              if (!exceptions.includes(column.field)) {
                this.goToService(row.id);
              }
            },
          };
        },
      },
      rowStyleOption: {
        clickHighlight: false,
        hoverHighlight: true,
      },
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      cellStyleOption: {
        bodyCellClass: ({ row }) => {
          if (
            row.technical_id === 45 ||
            row.technical_id === 48 ||
            row.operator_id === 49
          ) {
            return "is-info";
          } else if (row.service_type_id !== 0) {
            return `service-type service-type-${row.service_type_id}`;
          } else if (row.priority_id > 0) {
            return `priority priority-type${row.priority_id}`;
          }
        },
      },
      checkboxOption: {
        selectedRowChange: ({ selectedRowKeys }) => {
          // console.log(row, isSelected, selectedRowKeys);
          this.selectedRowKeys = selectedRowKeys;
        },
        selectedAllChange: ({ selectedRowKeys }) => {
          // console.log(isSelected, selectedRowKeys);
          this.selectedRowKeys = selectedRowKeys;
        },
      },
      selectedRowKeys: [],
      formTemplates: [],
      order_categories: [],
    };
  },
  watch: {
    searchQuery() {
      if (!this.watchInPause) {
        this.debouncedSearchQuery();
      }
    },
  },
  mounted() {
    this.$refs.searchInput.$el.children[0].focus();
  },
  created() {
    this.debouncedSearchQuery = debounce(this.getOrders, 500);
    this.getUsers();
    this.getUser();
    this.getTableColumns();
    this.getOrders();
    this.getServiceType();
    this.getWarrantyStatus();
    this.getFormTemplate().then((resp) => (this.formTemplates = resp.data));
    this.getCategoryItemsCall().then((resp) => (this.order_categories = resp));
    // document.addEventListener('keyup', this.tableNav)
  },
  destroyed() {
    document.getElementById("active-service-type")?.remove();
    // document.removeEventListener('keyup', this.tableNav);
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
      status: "auth/status",
      user: "auth/user",
      clientUrl: "clientUrl",
      formUrl: "formUrl",
    }),
    isSearch() {
      return this.searchQuery !== "" ||
        this.searchDeleted !== "0" ||
        this.status_id !== null ||
        this.dates.length > 0
        ? true
        : false;
    },
    searchQuery: {
      get() {
        return this.$store.state.records.searchQuery;
      },
      set(value) {
        let payload = {
          category: "searchQuery",
          value,
        };
        this.search(payload);
      },
    },
    searchDeleted: {
      get() {
        return this.$store.state.records.searchDeleted;
      },
      set(value) {
        let payload = {
          category: "searchDeleted",
          value,
        };
        this.search(payload);
      },
    },
    technical_id: {
      get() {
        return this.$store.state.records.technical_id;
      },
      set(value) {
        let payload = {
          category: "technical_id",
          value,
        };
        this.search(payload);
      },
    },
    category_id: {
      get() {
        return this.$store.state.records.category_id;
      },
      set(value) {
        let payload = {
          category: "category_id",
          value,
        };
        this.search(payload);
      },
    },
    status_id: {
      get() {
        return this.$store.state.records.status_id;
      },
      set(value) {
        let payload = {
          category: "status_id",
          value,
        };
        this.search(payload);
      },
    },
    dates: {
      get() {
        let dates = [];
        for (const date of this.$store.state.records.dates) {
          dates.push(new Date(date));
        }
        return dates;
      },
      set(value) {
        let payload = {
          category: "dates",
          value,
        };
        this.search(payload);
      },
    },
    trows() {
      return document.querySelectorAll("table .ve-table-body-tr");
    },
    repared_at_from() {
      return this.dates[0];
    },
    repared_at_to() {
      return this.dates[1];
    },
    columns() {
      function getDateFormat(field) {
        switch (field) {
          case "datepicker":
            return "yyyy-MM-dd";
          case "datetimepicker":
            return "yyyy-MM-dd HH:mm";
          case "timepicker":
            return "HH:mm";
          default:
            return "yyyy-MM-dd";
        }
      }
      let columns = [
        {
          field: "checkbox",
          key: "a",
          type: "checkbox",
          title: "",
          width: 50,
          fixed: "left",
          align: "center",
          canHide: false,
        },
        {
          field: "priority_id",
          key: "priority_id",
          title: this.dictionary["table_order_priority_id_field_label"],
          align: "center",
          canHide: true,
          renderBodyCell: ({ row }) => {
            let tpl;
            {
              row.priority_id > 0
                ? (tpl = (
                    <b style={`color: ${row.priority.colour}`}>
                      {row.priority.name} {row.priority_date}
                    </b>
                  ))
                : (tpl = <span></span>);
            }
            return tpl;
          },
        },
        {
          field: "action_buttons",
          key: "action_buttons",
          title: "",
          align: "right",
          fixed: "right",
          canHide: false,
          renderBodyCell: ({ row }) => {
            // const status_id = row['status_id'];
            // const operator_id = row['operator_id'];
            // const operator_confirmed = row['operator_confirmed'];
            const pdf_order = row["pdf_order"];
            const id = row["id"];
            // const user_id = this.$store.state.auth.user.id
            let tpl;

            // if(operator_confirmed && status_id === 10) {
            //   tpl = <div class="flex buttonWrap">
            //     {row.correspondence_token ? <span class="button is-small40 w40" v-tooltip={row.correspondence_last?.created_at} on-click={() => this.triggerCorrespondenceLink(row.correspondence_token)}><span data-writer={ row.correspondence_last ? row.correspondence_last.writer : 's' } class="writer"></span><svg data-v-32a52071="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></span> : <span class="button is-small40 w40" on-click={() => this.triggerCorrespondenceModal(row)}><icon name="inbox"></icon></span>}
            //     <span class="button is-small40 w40" on-click={() => this.triggerSendModal(row)}><icon name="mail"></icon></span>
            //     <span class="button is-small40" style={this.statusColor(status_id)} on-click={() => this.confirmTheService(row) }>Potwierdz zlecenie</span>
            //     {row.is_active === true ? <span class="button is-small40" on-click={() => this.orderRemove(id) }><div><icon name="trash"></icon></div></span> : ''}
            //   </div>
            // } else if(!operator_confirmed && operator_id === user_id) {
            //   tpl = <div class="flex buttonWrap">
            //     {row.correspondence_token ? <span class="button is-small40 w40" v-tooltip={row.correspondence_last?.created_at} on-click={() => this.triggerCorrespondenceLink(row.correspondence_token)}><span data-writer={ row.correspondence_last ? row.correspondence_last.writer : 's' } class="writer"></span><svg data-v-32a52071="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></span> : <span class="button is-small40 w40" on-click={() => this.triggerCorrespondenceModal(row)}><icon name="inbox"></icon></span>}
            //     <span class="button is-small40 w40" on-click={() => this.triggerSendModal(row)}><icon name="mail"></icon></span>
            //     <span class="button is-small40" style={this.statusColor(status_id)} on-click={() => this.confirmTheService(row) }>Potwierdz zlecenie</span>
            //     {row.is_active === true ? <span class="button is-small40" on-click={() => this.orderRemove(id) }><div ><icon name="trash"></icon></div></span> : ''}
            //   </div>
            // } else {
            //   tpl = <div class="flex buttonWrap">
            //     {row.correspondence_token ? <span class="button is-small40 w40" v-tooltip={row.correspondence_last?.created_at} on-click={() => this.triggerCorrespondenceLink(row.correspondence_token)}><span data-writer={ row.correspondence_last ? row.correspondence_last.writer : 's' } class="writer"></span><svg data-v-32a52071="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg></span> : <span class="button is-small40 w40" on-click={() => this.triggerCorrespondenceModal(row)}><icon name="inbox"></icon></span>}
            //     <span class="button is-small40 w40" on-click={() => this.triggerSendModal(row)}><icon name="mail"></icon></span>
            //     {/* <a href={pdf_order} target="_blank" class="button is-small40"><div>PDF</div></a> */}
            //     {row.is_active === true ? <span class="button is-small40" on-click={() => this.orderRemove(id) }><div ><icon name="trash"></icon></div></span> : ''}
            //   </div>
            // }
            tpl = (
              <div class="flex buttonWrap">
                {row.correspondence_token ? (
                  <span
                    class="button is-small40 w40"
                    v-tooltip={row.correspondence_last?.created_at}
                    on-click={() =>
                      this.triggerCorrespondenceLink(row.correspondence_token)
                    }
                  >
                    <span
                      data-writer={
                        row.correspondence_last
                          ? row.correspondence_last.writer
                          : "s"
                      }
                      class="writer"
                    ></span>
                    <svg
                      data-v-32a52071=""
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-message-square"
                    >
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>
                  </span>
                ) : (
                  <span
                    class="button is-small40 w40"
                    on-click={() => this.triggerCorrespondenceModal(row)}
                  >
                    <icon name="inbox"></icon>
                  </span>
                )}
                <span
                  class="button is-small40 w40"
                  on-click={() => this.triggerSendModal(row)}
                >
                  <icon name="mail"></icon>
                </span>
                <a href={pdf_order} target="_blank" class="button is-small40">
                  <div>PDF</div>
                </a>
                {row.is_active === true ? (
                  <span
                    class="button is-small40"
                    on-click={() => this.orderRemove(id)}
                  >
                    <div>
                      <icon name="trash"></icon>
                    </div>
                  </span>
                ) : (
                  ""
                )}
              </div>
            );
            return tpl;
          },
        },
      ];

      if (this.rawTableColumns?.default) {
        const showEllipsis = [
          "technical_notes",
          "technical_service",
          "service_fault",
          "service_description",
          "device_service_work",
          "input_1693906624",
        ];
        const showYesOrNo = ["is_fv", "is_paid", "is_warranty", "is_trade"];
        for (let item of this.rawTableColumns.default) {
          let column = {
            field: item.field_name,
            key: item.field_name,
            title: item.name,
            canHide: true,
          };
          if (showEllipsis.includes(item.field_name)) {
            column["ellipsis"] = { showTitle: true, lineClamp: 2 };
          }
          if (showYesOrNo.includes(item.field_name)) {
            const renderBodyCell = ({ row, column }) => {
              const cellData = row[column.field];
              return (
                <span class={cellData ? "text-green" : ""}>
                  {cellData ? "TAK" : "NIE"}
                </span>
              );
            };
            column["renderBodyCell"] = renderBodyCell;
          }
          if (item.field_name === "technical_name") {
            column = {
              field: item.field_name,
              key: item.field_name,
              title: item.name,
              align: "left",
              canHide: true,
              renderBodyCell: ({ row }) => {
                return <TableSelectTechnican data={row} key={row.status_id} />;
              },
            };
          }
          if (item.field_name === "status_id") {
            column = {
              field: item.field_name,
              key: item.field_name,
              title: item.name,
              align: "left",
              canHide: true,
              renderBodyCell: ({ row }) => {
                {
                  /* const cellData = row[column.field]; 
                  let tpl
                  if(cellData === 10) {
                    tpl = <span style={this.statusColor(cellData)}>{this.statusName(cellData)}</span> 
                  } else {
                    tpl = <div class="flex buttonWrap" on-click={() => this.triggerStatusModal(row) }><span style={this.statusColor(cellData)}>{this.statusName(cellData)}</span><icon name="edit" style="width: 14px;flex-shrink: 0"></icon></div>
                  }
                  return tpl */
                }
                return (
                  <TableSelectStatus
                    data={row}
                    on-update={(resp) => this.triggerDataChange(resp)}
                  />
                );
              },
            };
          }
          if (item.field_name === "category_id") {
            column = {
              field: item.field_name,
              key: item.field_name,
              title: item.name,
              align: "center",
              sortBy: "",
              canHide: true,
              renderBodyCell: ({ row, column }) => {
                const cellData = row[column.field];
                let tpl;
                const category = this.order_categories.find(
                  (item) => item.id === cellData
                );

                {
                  cellData > 0
                    ? (tpl = <span>{category.name}</span>)
                    : (tpl = <span></span>);
                }
                return tpl;
              },
            };
          }
          if (item.field_name === "warranty_status_id") {
            column = {
              field: item.field_name,
              key: item.field_name,
              title: item.name,
              align: "left",
              canHide: true,
              renderBodyCell: ({ row, column }) => {
                const cellData = row[column.field];
                return this.warrantyStatusName(cellData);
              },
            };
          }
          if (item.field_name === "repared_at") {
            column = {
              field: item.field_name,
              key: item.field_name,
              title: item.name,
              width: 125,
              align: "center",
              canHide: true,
            };
          }
          columns.splice(columns.length - 1, 0, column);
        }
      }

      if (this.rawTableColumns?.dedicated) {
        const showEllipsis = ["input_1693906624"];
        const dateTypeField = ["datepicker", "datetimepicker", "timepicker"];
        for (let item of this.rawTableColumns.dedicated) {
          let column = {
            field: item.field_name,
            key: "dedicated_form_fields" + item.field_name,
            title: item.name,
            canHide: true,
            renderBodyCell: ({ row, column }) => {
              const cellData = row["dedicated_form_fields"][column.field];
              let tpl;
              if (dateTypeField.includes(item.field_type)) {
                const result = cellData
                  ? format(new Date(cellData), getDateFormat(item.field_type))
                  : "-";
                tpl = result;
              } else {
                tpl = cellData;
              }
              return tpl;
            },
          };
          if (showEllipsis.includes(item.field_name)) {
            column["ellipsis"] = { showTitle: true, lineClamp: 3 };
          }
          columns.splice(columns.length - 1, 0, column);
        }
      }

      let column_place_id = {
        field: "place_id",
        key: "place_id",
        title: this.dictionary["table_order_place_id_field_label"],
        canHide: true,
        renderBodyCell: ({ row }) => {
          let tpl;
          {
            row.place_id > 0
              ? (tpl = <b>{row.place.name}</b>)
              : (tpl = <span></span>);
          }
          return tpl;
        },
      };
      columns.splice(columns.length - 1, 0, column_place_id);
      return columns;
    },
  },
  methods: {
    ...mapActions({
      getOrdersCall: "orders/getOrders",
      removeOrderCall: "orders/removeOrder",
      confirmTheServiceCall: "orders/confirmOrder",
      search: "records/search",
      getServiceTypeCall: "orders/serviceType",
      getWarrantyStatusCall: "orders/warrantyStatus",
      sentEmailCall: "orders/sendEmail",
      sendCorrespondenceCall: "orders/sendCorrespondence",
      removeCorrespondenceCall: "orders/removeCorrespondence",
      getTableColumnsCall: "orders/getTableColumns",
      getUser: "auth/userDetails",
      updateUser: "auth/userUpdate",
      getFormTemplate: "orders/getFormTemplate",
      getUsersCall: "records/getUsers",
      getOrderPriorityCall: "orders/getOrderPriority",
      getCategoryItemsCall: "orders/getCategoryItems",
    }),
    triggerDataChange(resp) {
      const data = resp.data;
      const idx = this.orders.findIndex((item) => item.id === data.id);
      this.orders[idx].status_id = data.status_id;
    },
    getUsers() {
      this.loading = true;

      this.getUsersCall({
        page: 1,
        perPage: 99999,
        sortField: "lastname",
        sortOrder: "asc",
        searchQuery: "",
        searchDeleted: 0,
        function_role: "technical",
      })
        .then((resp) => {
          this.technicians = resp.data;
        })
        .catch((error) => {
          this.technicians = [];
          this.total = 0;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        })
        .finally(() => (this.loading = false));
    },
    goToService(id) {
      this.$router.push({ name: "ServiceDetails", params: { id } });
    },
    setCellSelection(rowKey, colKey) {
      this.$refs["tableRef"].setCellSelection({ rowKey, colKey });
    },
    getWarrantyStatus() {
      this.getWarrantyStatusCall()
        .then((resp) => (this.warrantyStatus = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getOrders() {
      this.loading = true;
      const {
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        category_id,
        repared_at_from,
        repared_at_to,
        technical_id,
        status_id,
      } = this;

      this.getOrdersCall({
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        category_id,
        repared_at_from,
        technical_id,
        repared_at_to,
        status_id,
      })
        .then((resp) => {
          // this.checkboxGroup = this.columns.filter(item => item.canHide).map(a => a.key)
          if (this.user?.table_order_fields) {
            this.checkboxGroup = this.user.table_order_fields.split(",");
          } else {
            this.checkboxGroup = [
              "number",
              "customer_name",
              "device_type",
              "repared_at",
              "technical_name",
              "status_id",
              "is_fv",
              "is_paid",
              "is_warranty",
              "warranty_status_id",
            ];
          }
          this.changeTableColumns();

          this.total = resp.page.items;
          if (this.total <= this.perPage) {
            document.querySelector(".table-pagination").style.display = "none";
          }
          this.orders = resp.data;
          // console.log(this.$refs.tableRef)
          {
            /* setTimeout(function() {
              Array.from(document.querySelectorAll('table tbody tr')).forEach(node => { node.classList.add('normal') })
            }, 300)   */
          }
        })
        .catch((error) => {
          this.orders = [];
          this.total = 0;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        })
        .finally(() => (this.loading = false));
    },
    orderRemove(id) {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć zlecenie?",
          content: `<span class='is-danger'>Usunięcia zlecenia nie można cofnąć.</span> Zlecenie pozostanie w utworzonych do tej pory protokołach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeOrderCall({ id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getOrders();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    onPageSizeChange(pageSize) {
      this.perPage = pageSize;
      this.onPageChange(1);
    },
    onPageChange(page) {
      this.page = page;
      let payload = {
        category: "page",
        value: page,
      };
      this.search(payload);
      if (this.searchQuery === "" || this.searchQuery === null) {
        this.getOrders();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.getOrders();
    },
    tableNav($event) {
      var trows = this.trows,
        trow,
        nextrow;

      switch ($event.keyCode) {
        case 13: {
          return active();
        }
        case 38: {
          return movehighlight(-1, $event);
        }
        case 40: {
          return movehighlight(1, $event);
        }
        default: {
          return true;
        }
      }

      function active() {
        document.querySelector("table tbody tr.is-selected a").click();
      }

      function movehighlight(way, e) {
        e.preventDefault && e.preventDefault();
        e.returnValue = false;
        var idx = highlightRow(true); //gets current index or null if none highlighted
        if (typeof idx === "number") {
          //there was a highlighted row
          idx += way; //increment\decrement the index value
          if (idx && (nextrow = trows[idx])) {
            return highlightRow.apply(nextrow);
          } //index is > 0 and a row exists at that index
          else if (idx) {
            return highlightRow.apply(trows[1]);
          } //index is out of range high, go to first row
          return highlightRow.apply(trows[trows.length - 1]); //index is out of range low, go to last row
        }
        return highlightRow.apply(trows[way > 0 ? 1 : trows.length - 1]); //none was highlighted - go to 1st if down arrow, last if up arrow
      }

      function highlightRow(gethighlight) {
        //now dual use - either set or get the highlighted row
        gethighlight = gethighlight === true;
        var t = trows.length;
        while (--t > -1) {
          trow = trows[t];
          console.log(trow);
          if (gethighlight && trow.classList.contains("is-selected")) {
            return t;
          } else if (!gethighlight && trow !== this) {
            trow.classList.add("normal");
          }
        } //end while

        return gethighlight
          ? null
          : this.classList.add(
              this.classList.contains("is-selected") ? "normal" : "is-selected"
            );
      }
    },
    toggleSearchDeleted() {
      this.debouncedSearchQuery();
    },
    addNewOrder() {
      Promise.all([
        this.$store.commit("orders/setOrder", {}),
        this.$store.commit("orders/setCustomer", {}),
        this.$store.commit("orders/setTechnician", {}),
        this.$store.commit("orders/setDevice", {}),
      ]).then(() => this.$router.push({ name: "ServiceAdd" }));
    },
    searchOrdersFromRange() {
      this.getOrders();
    },
    searchOrdersFromStatus() {
      this.getOrders();
    },
    allOrders() {
      this.dates = [];
      this.getOrders();
    },
    confirmTheService(order) {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz potwierdzić zlecenie?",
          content: ``,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "check",
          submit: `<span class="mal50i">Potwierdzam</span>`,
          ymConfirmClass: "button is-medium is-primary",
          ymConfirm: true,
          ymOnConfirm: () => {
            this.confirmTheServiceCall({ order, id: order.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getOrders();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    statusName: function (id) {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === id
      );
      if (status.length > 0) {
        return status[0].name;
      }
    },
    statusColor: function (id) {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
    async cleanSearch() {
      this.technical_id = null;
      this.watchInPause = true;
      this.searchQuery = "";
      this.searchDeleted = "0";
      this.status_id = null;
      this.dates = [];
      await this.getOrders();
      this.watchInPause = false;
    },
    rowClassChecker(row) {
      if (
        row.technical_id === 45 ||
        row.technical_id === 48 ||
        row.operator_id === 49
      ) {
        return "is-info";
      } else if (row.service_type_id !== 0) {
        return `service-type service-type-${row.service_type_id}`;
      }
    },
    getServiceType() {
      this.getServiceTypeCall()
        .then((resp) => (this.serviceType = resp))
        .then(() => {
          const activeServiceType = this.serviceType.filter(
            (item) => item.is_active
          );
          const styles = [];
          for (let sType of activeServiceType) {
            const style = `.service-type-${sType.id} {background-color: ${sType.colour};}`;
            styles.push(style);
          }
          document.head.insertAdjacentHTML(
            "beforeend",
            `<style id="active-service-type">${styles.join("")}</style>`
          );
        })
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    warrantyStatusName(id) {
      const status = this.warrantyStatus.find((item) => item.id === id);
      return id === 0 ? "-" : status?.name;
    },
    changeTableColumns() {
      const allColumns = this.columns
        .filter((item) => item.canHide)
        .map((a) => a.key);
      let difference = allColumns
        .filter((x) => !this.checkboxGroup.includes(x))
        .concat(this.checkboxGroup.filter((x) => !allColumns.includes(x)));
      this.showColumns(allColumns);
      this.hideColumns(difference);
      if (this.checkboxGroup.join(",") !== this.user?.table_order_fields) {
        const user = {
          ...this.user,
          table_order_fields: this.checkboxGroup.join(","),
        };
        this.updateUser(user);
      }
      this.showTableModal = false;
    },
    hideColumns(keys) {
      this.$refs["tableRef"].hideColumnsByKeys(keys);
    },
    showColumns(keys) {
      this.$refs["tableRef"].showColumnsByKeys(keys);
    },
    triggerSendModal(order) {
      this.showSendModal = true;
      this.modalOrder = order;
    },
    triggerStatusModal(order) {
      this.modalStatusId = order.status_id;
      this.showStatusModal = true;
      this.modalOrder = order;
    },
    emptyModalTrigger() {
      this.correspondence = {
        subject: "",
        message: "",
      };
      this.mail = {
        subject: "",
        content: "",
      };
      this.$nextTick(() => {
        this.$refs.correspondenceObserver.reset();
        this.$refs.observer.reset();
      });
      this.modalStatusId = null;
      this.modalOrder = null;
    },
    changeOrderStatus() {
      const order = { ...this.modalOrder, status_id: this.modalStatusId };
      this.confirmTheServiceCall({ order, id: order.id }).then((data) => {
        this.getOrders();
        this.$buefy.toast.open({
          duration: 5000,
          message: data.alert[1],
          type: "is-success",
          position: "is-bottom",
        });
        this.showStatusModal = false;
      });
    },
    onEmailSendSubmit() {
      let { subject, content } = this.mail;
      const order_ids = [];
      if (this.modalOrder?.id) {
        order_ids.push(this.modalOrder.id);
        if (
          this.modalOrder.dedicated_form_fields &&
          Object.keys(this.modalOrder.dedicated_form_fields).length > 0
        ) {
          content += `<br /><br /><p>Numer zlecenia:</p><b>${this.modalOrder.fullnumber}</b><br />`;
          content += `<p>Nazwa automatu lub nr seryjny:</p><b>${this.modalOrder.dedicated_form_fields.input_1693906549}</b><br>`;
          content += `<p>Nazwa ogólna lub kod części:</p><b>${this.modalOrder.dedicated_form_fields.input_1693907064}</b><br>`;
        }
      } else if (this.selectedRowKeys.length > 0) {
        order_ids.push(...this.selectedRowKeys);
      }
      this.sentEmailCall({ subject, content, order_ids }).then(() => {
        this.showSendModal = false;
      });
    },
    getTableColumns() {
      this.getTableColumnsCall().then((data) => {
        this.rawTableColumns = data?.fields;
      });
    },
    triggerCorrespondenceLink(token) {
      const correspondence_url = `${this.clientUrl.content}/correspondence/${token}`;
      window.open(correspondence_url, "_blank");
    },
    triggerCorrespondenceModal(item) {
      this.showCorrespondenceModal = true;
      this.modalOrder = item;
    },
    onCorrespondenceSendSubmit() {
      let { subject, message } = this.correspondence;
      const order_id = this.modalOrder.id;
      message += `<br /><br /><p>Numer zlecenia:</p><b>${this.modalOrder.fullnumber}</b><br />`;
      if (
        this.modalOrder.dedicated_form_fields &&
        Object.keys(this.modalOrder.dedicated_form_fields).length > 0
      ) {
        message += `<p>Nazwa automatu lub nr seryjny:</p><b>${this.modalOrder.dedicated_form_fields.input_1693906549}</b><br>`;
        message += `<p>Nazwa ogólna lub kod części:</p><b>${this.modalOrder.dedicated_form_fields.input_1693907064}</b><br>`;
      }

      this.sendCorrespondenceCall({ subject, message, order_id }).then(
        (data) => {
          const idx = this.orders.findIndex(
            (item) => item.id === data.data.order.id
          );
          const tableIdx = this.$refs.tableRef.actualRenderTableData.findIndex(
            (item) => item.id === data.data.order.id
          );
          this.orders[idx].correspondence_token =
            data.data.order.correspondence_token;
          this.$refs.tableRef.actualRenderTableData[
            tableIdx
          ].correspondence_token = data.data.order.correspondence_token;
          this.showCorrespondenceModal = false;
        }
      );
    },
    removeCorrespondace(token) {
      this.removeCorrespondenceCall(token);
    },
    addNewOrderFromForm() {
      this.showFromOrderModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.ping {
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}
</style>
<style lang="scss">
tr.is-info {
  background: #7dbbff;
  color: #fff;
}
table.table tbody tr.is-info td,
.b-table .table tbody tr.is-info td,
table.table tbody tr.is-info td a,
table.table tbody tr.service-type-3 td,
.b-table .table tbody tr.service-type-3 td,
table.table tbody tr.service-type-3 td a {
  color: #fff;
}
table.table tbody tr.is-info:hover td,
.b-table .table tbody tr.is-info:hover td,
table.table tbody tr.is-info:hover td a,
table.table tbody tr.service-type-3:hover td,
.b-table .table tbody tr.service-type-3:hover td,
table.table tbody tr.service-type-3:hover td a {
  color: #000;
}
table.table tbody tr.is-info td a.button,
table.table tbody tr.service-type-3 td a.button {
  color: initial;
}
.flex {
  display: flex;
  gap: 8px;
}
.text-green {
  color: green;
}
.b-field .dropdown-menu {
  z-index: 120;
}
.ve-table
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  tr.ve-table-body-tr.is-selected
  td {
  background-color: #e0f3ff !important;
}
td[col-key="priority_id"],
td[col-key="place_id"] {
  vertical-align: middle;
}
.w40 {
  width: 40px !important;
  padding: 0 8px;
}
.writer {
  position: absolute;
  width: 16px;
  height: 16px;
  top: -8px;
  right: -8px;
  background-color: #000;
  margin: 0;
  border: 1px solid black;
  border-radius: 50%;

  &[data-writer]:before {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
  }
  &[data-writer="s"]:before {
    content: "b";
  }
  &[data-writer="r"]:before {
    content: "k";
  }
  &[data-writer="r"] {
    background-color: green;
    border-color: green;
  }
}
td[col-key="status_id"],
td[col-key="technical_name"] {
  overflow: unset !important;
}
</style>
